<div #toolbar class="toolbar">
    <!-- <div class="buttonNavbar">
        <button mat-icon-button *ngIf="openNabvarButton" (click)="openNavbarMenu()">
            <mat-icon *ngIf="!openNavbar">
                menu
            </mat-icon>
            <mat-icon *ngIf="openNavbar">
                close
            </mat-icon>
        </button>
    </div> -->
    <div class="logo-Container">
        <img src="assets/images/brand-logo.svg" class="tb-logo" />
    </div>
    




    <!-- <mat-menu #menu="matMenu">
        <button mat-menu-item>
            Mi cuenta
        </button>
        <button mat-menu-item>
            Cerrar sesión
        </button>
    </mat-menu> -->
</div>