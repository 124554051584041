import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { MembreciaComponent } from './pages/membrecia/membrecia.component';
import { DocumentsComponent } from './pages/documents/documents.component';

const routes: Routes = [
  {
    path:'documents',
    loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path:'home',
    component:HomeComponent
  },
  {
    path:'membresia',
    component: MembreciaComponent
  },
  {
    path:'**',
    redirectTo:'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
