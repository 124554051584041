import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { delay } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { ComingSoonMessageComponent } from 'src/app/dialogs/coming-soon-message/coming-soon-message.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('toolbar') toolbar: ElementRef = new ElementRef(null);
  public openNabvarButton = false;
  public openNavbar = false;
  public panelUrl =  environment.panelUrl;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public matDialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 985px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        //700px
        if (res.matches) {
          this.openNabvarButton = true;
          this.openNavbar = false;
        } else {
          this.openNabvarButton = false;
          this.openNavbar = false;
        }
      })
  }


  public openNavbarMenu(){
    
    this.openNavbar = !this.openNavbar;
    if(this.openNavbar){
      //this.passDataService.changeMessage('openNavbar');
    }else {
      //this.passDataService.changeMessage('closeNavbar');
    }
  }


  public openComingSoon(){
    const dialogRef = this.matDialog.open(ComingSoonMessageComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  public goToPanel(){
    window.open( 'https://' + this.panelUrl, '_blank');
  }


}
